import { DataStore } from "@aws-amplify/datastore";
import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ChoosePlayerControl } from "../controls/ChoosePlayerControl";
import { Field, Game, GameSeries, Stone, StoneType } from "../models";
import {
  addGameToLocalStorage,
  createGame,
  createRematch,
} from "../utils/common";
import {
  GameInfoHeaderControl,
  GameStatusInfoControl,
} from "./Game/GameInfoHeaderControl";
import { GameBoardControl } from "./Game/GameBoardControl";
import { RemainingStonesControl, SelectStoneDialog } from "./SelectStoneDialog";

import { CurrentStoneInfoControl } from "./Game/CurrentStoneInfoControl";
import { ShareGameLinkControl } from "./ShareGameLinkControl";
import { getGameConfig } from "../utils/gameConfig";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

interface GameProps {}
export function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#101010" : "#fff",
        color: (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "grey.800" : "grey.300",
        p: 1,
        borderRadius: 2,
        textAlign: "center",
        fontSize: "0.875rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}
interface GraphQLResult<T> {
  // data?: object;
  data?: T | Record<string, any>;
  extensions?: {
    [key: string]: any;
  };
}

export interface BoardField extends Field {
  color?: String;
  hover?: Boolean;
  error?: Boolean;
  showConfirm?: Boolean;
}

interface WinAttributes {
  color?: number[];
  size?: number[];
  shape?: number[];
  variant?: number[];
}

const checkAttribute = (
  key: keyof WinAttributes,
  winAttr: WinAttributes,
  stone: StoneType
) => {
  if (
    (winAttr && winAttr[key]!.length === 0) ||
    winAttr![key]!.indexOf(stone![key]!) !== -1
  ) {
    winAttr[key]!.push(stone![key]!);
  }
  return winAttr;
};

const hasWon = (
  board: (Field | null)[],
  expertMode?: boolean | null
): boolean | number[] => {
  // 0   1   2   3
  // 4   5   6   7
  // 8   9   10  11
  // 12  13  14  15
  if (!board || board === null) {
    return false;
  }

  const winRows = [
    [0, 1, 2, 3],
    [4, 5, 6, 7],
    [8, 9, 10, 11],
    [12, 13, 14, 15],
    [0, 4, 8, 12],
    [1, 5, 9, 13],
    [2, 6, 10, 14],
    [3, 7, 11, 15],
    [0, 5, 10, 15],
    [3, 6, 9, 12],
  ];

  if (expertMode === true) {
    // 0   1   2   3
    // 4   5   6   7
    // 8   9   10  11
    // 12  13  14  15
    console.log("expert mode here...");
    const expertWinRows = [
      [0, 1, 4, 5],
      [1, 2, 5, 6],
      [2, 3, 6, 7],
      [4, 5, 8, 9],
      [5, 6, 9, 10],
      [6, 7, 10, 11],
      [8, 9, 12, 13],
      [9, 10, 13, 14],
      [10, 11, 14, 15],
    ];
    winRows.push(...expertWinRows);
  }

  for (let i = 0; i < winRows.length; i++) {
    const winRow = winRows[i];

    let winAttr: WinAttributes = {
      color: [],
      size: [],
      shape: [],
      variant: [],
    };
    for (let j = 0; j < winRow.length; j++) {
      // console.log("check field", winRow[j]);
      // console.log("🚀 ~ board[winRow[j]]", board[winRow[j]]);
      if (!board[winRow[j]]?.stone) {
        // console.log(
        //   `field ${board[winRow[j]]?.name} empty, so check next winRow`
        // );
        break;
      } else if (board[winRow[j]]?.stone) {
        const stone = board[winRow[j]]?.stone;
        console.log("🚀 ~ board[winRow[j]].name", board[winRow[j]]?.name);

        if (j === 0) {
          winAttr.color?.push(stone!.color!);
          winAttr.size?.push(stone!.size!);
          winAttr.shape?.push(stone!.shape!);
          winAttr.variant?.push(stone!.variant!);
        } else {
          winAttr = checkAttribute("color", winAttr, stone!);
          winAttr = checkAttribute("size", winAttr, stone!);
          winAttr = checkAttribute("shape", winAttr, stone!);
          winAttr = checkAttribute("variant", winAttr, stone!);
        }
      }
    }
    if (
      winAttr.color?.length === 4 ||
      winAttr.size?.length === 4 ||
      winAttr.shape?.length === 4 ||
      winAttr.variant?.length === 4
    ) {
      return winRow;
    }
  }
  return false;
};

export const updateGameInDB = async (
  id: string,
  newGame: Partial<Game>,
  setGame?: (g: Game) => void
) => {
  console.log("updating game", newGame);
  const original = await DataStore.query(Game, id);
  const update = await DataStore.save(
    Game.copyOf(original!, (updateGame) => {
      const updateKeys = Object.keys(newGame);
      console.log("update KEys: ", updateKeys);
      for (let i = 0; i < updateKeys.length; i++) {
        if (
          updateKeys[i] === "id" ||
          updateKeys[i] === "updatedAt" ||
          updateKeys[i] === "createdAt"
        ) {
          continue;
        }
        console.log("update key: ", updateKeys[i]);
        // @ts-ignore
        updateGame[updateKeys[i] as keyof Game] =
          newGame[updateKeys[i] as keyof Game];
        console.log("🚀 ~ updateGame", updateGame);
      }
    })
  );
  console.log("🚀 ~ update", update);
  if (setGame) setGame({ ...update });
  return update;
};

export function GameView(props: GameProps) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("sm"));

  let params = useParams();

  let [stoneSet, setStoneSet] = React.useState<Stone[]>();
  console.log("🚀 ~ stoneSet", stoneSet);
  let [game, setGame] = React.useState<Game>();
  let [series, setSeries] = React.useState<GameSeries>();
  // let [board, setBoard] = React.useState<BoardField[]>();

  let [stone, setStone] = React.useState<StoneType>();
  let [gameNotFound, setGameNotFound] = React.useState(false);
  let [winRow, setWinRow] = React.useState<number[]>();
  let [winner, setWinner] = React.useState<string>();
  // let [hoverKey, setHoverKey] = React.useState<string>();
  let [lastUpdate, setLastUpdate] = React.useState<string>();
  let [reload, setReload] = React.useState(false);

  let [player, setPlayer] = React.useState<number>();
  const [open, setOpen] = React.useState(false);

  const handleOpenStoneSelectDialog = () => {
    console.log("🚀 ~ 'Open Stone Select Dialog'", "Open Stone Select Dialog");
    setOpen(true);
  };

  const handleClose = (value: StoneType) => {
    setOpen(false);
    setStone(value);
    updateGameInDB(game!.id, { currentStone: value as StoneType }, setGame);
  };

  const selectPlayer = async (x: string, game: Game, name?: string) => {
    console.log("🚀 ~ selectPlayer: game", game);
    localStorage.setItem(game.id, JSON.stringify({ player: x }));

    console.log("Select Player: ");

    if (Number(x) === 1) {
      updateGameInDB(
        game.id,
        { player1: name || t("CreateGameDefaults.player1") },
        setGame
      );
    } else if (Number(x) === 2) {
      updateGameInDB(
        game.id,
        { player2: name || t("CreateGameDefaults.player2") },
        setGame
      );
    }
    setPlayer(Number(x));
  };
  const checkPlayers = async (game: Game) => {
    console.log("check players", game);
    const gameFromStorage = JSON.parse(localStorage.getItem(game.id) || "{}");
    const player = gameFromStorage.player;
    if (player) {
      setPlayer(Number(player));
    } else {
      console.log("no players, ", game?.rejoinedPlayers);

      if (game?.rejoinedPlayers && game?.rejoinedPlayers.length === 1) {
        console.log(
          "seems to be a rematch, so set to last remaining player..."
        );
        if (game?.rejoinedPlayers[0] === 1) {
          setPlayer(2);
          localStorage.setItem(game.id, JSON.stringify({ player: 2 }));
          updateGameInDB(game!.id, { rejoinedPlayers: [1, 2] }, setGame);
        } else {
          setPlayer(1);
          localStorage.setItem(game.id, JSON.stringify({ player: 1 }));
          updateGameInDB(game!.id, { rejoinedPlayers: [2, 1] }, setGame);
        }
      }
      // if (game?.player1 && !game?.player2) {
      //   console.log(" choosePlayer 2 ");
      //   await selectPlayer("2", game);
      // } else if (!game?.player1 && game?.player2) {
      //   console.log(" choosePlayer 1 ");
      //   await selectPlayer("1", game);
      // }
    }
    if (game?.player1 && game?.player2 && !game?.currentPlayer) {
      console.log("no current player set", game);
      updateGameInDB(game!.id, { currentPlayer: 1 }, setGame);
    }
  };

  const initGame = async (gameId?: string) => {
    const tmpStoneSet: Stone[] = await DataStore.query(Stone);
    setStoneSet([...tmpStoneSet]);
    console.log("🚀 ~ initGame", gameId);
    if (gameId !== undefined || game?.id) {
      gameId = gameId || game!.id;

      const gameTmp = await DataStore.query(Game, gameId);
      // addGameToLocalStorage(gameId);
      addGameToLocalStorage(gameTmp);

      if (gameTmp !== undefined) {
        console.log("🚀 ~ gameTmp", gameTmp);
        if (hasWon(gameTmp.board!, gameTmp.expertMode)) {
          // if (hasWon(gameTmp.board!, true)) {
          console.log("won, but wrong data?");
        }

        await checkPlayers(gameTmp);
        if (gameTmp.currentStone) {
          console.log("there is a stone, so set...");
          setStone(gameTmp.currentStone);
        } else {
          setStone(undefined);
        }
        if (gameTmp.seriesId) {
          const series = await DataStore.query(GameSeries, gameTmp.seriesId!);
          if (series) {
            setSeries({ ...series });
          }
        }
      }
      if (gameTmp) setGame({ ...gameTmp });
      if (gameTmp?.winRow) setWinRow(gameTmp!.winRow as number[]);
      if (!gameTmp) setGameNotFound(true);
    }
    console.log("stoneSet: ", stoneSet);
    console.log("🚀 ~ stone", stone);
    if (stone === undefined && winRow === undefined) {
      handleOpenStoneSelectDialog();
    }
  };

  const placeStone = async (index: number) => {
    console.log(` wsss Player "${game?.currentPlayer}" is placing stone...`);
    const boardField: BoardField = {
      ...game!.board![index],
      stone: stone,
    };

    const currentPlayer = game?.currentPlayer;
    console.log("🚀 ~ wsss currentPlayer", currentPlayer);
    let board = [...game!.board!];
    console.log("🚀 ~ board confirmed", board);
    board[index] = { ...boardField };

    const nextPlayer = game?.currentPlayer === 1 ? 2 : 1;

    let updatedGameFields = {
      board: board,
      currentPlayer: nextPlayer,
      currentStone: null,
      gameHasStarted: true,
    };
    // if (!game?.gameHasStarted) {
    //   const startGame = await updateGameInDB(
    //     game!.id,
    //     { gameHasStarted: true },
    //     setGame
    //   );
    // }

    const winRow = hasWon(board, game?.expertMode);
    // const winRow = hasWon(board, true);
    if (winRow) {
      let currentPlayerName =
        currentPlayer === 1 ? game?.player1 : game?.player2;
      const updatedGame = await updateGameInDB(
        game!.id,
        {
          ...updatedGameFields,
          winner: currentPlayerName,
          winRow: winRow as number[],
        },
        setGame
      );
      console.log(`${winner}, you are the WINNER!!!!!!!!`);
    } else {
      const updatedGame = await updateGameInDB(
        game!.id,
        updatedGameFields,
        setGame
      );
    }
    setStone(undefined);
  };
  const navigate = useNavigate();
  const [namespacesLoaded, setNamespacesLoaded] = React.useState({
    gameControl: false,
    shareGame: false,
    StoneSelectToolTips: false,
  });

  const { t } = useTranslation();

  React.useEffect(() => {
    const loadNamespaces = async () => {
      try {
        await i18next.loadNamespaces([
          "gameControl",
          "shareGame",
          "StoneSelectToolTips",
        ]);
        setNamespacesLoaded({
          gameControl: true,
          shareGame: true,
          StoneSelectToolTips: true,
        });
      } catch (err) {
        console.error("Error loading namespaces", err);
      }
    };

    loadNamespaces();

    if (reload) {
      console.log("reload set.. so reload");
      setReload(false);
      window.location.reload();
    }

    if (params.gameId) initGame(params.gameId);

    const sub = DataStore.observeQuery(Game, (g) =>
      g.id("eq", params.gameId!)
    ).subscribe(({ items }) => {
      console.log("got update...", items[0].updatedAt);
      setLastUpdate(items[0].updatedAt!);
    });

    window.addEventListener("focus", () => {
      console.log("viewed");
      initGame(params.gameId);
    });

    return () => {
      sub.unsubscribe();
      window.removeEventListener("focus", () => {
        console.log("viewed");
        initGame(params.gameId);
      });
    };
  }, [params.gameId, lastUpdate, reload]);

  const allNamespacesLoaded = Object.values(namespacesLoaded).every(Boolean);

  if (!game && gameNotFound) {
    return (
      <Box display={"flex"} flexDirection="column" alignItems={"center"}>
        <Typography
          m={2}
          variant="h5"
          textAlign={"center"}
          sx={{ fontSize: "smaller" }}
        >
          {t("gameControl:gameNotFound", { gameId: params.gameId })}
        </Typography>
        <Button
          // sx={{ maxWidth: "200px" }}
          color="primary"
          variant="outlined"
          onClick={() => createGame(undefined, navigate)}
        >
          {t("gameControl:createNewGameButton")}
        </Button>
      </Box>
    );
  } else if (!game) {
    return <></>;
  }

  // const gameStarted = true;
  const gameStarted =
    game.player1 && game.player2 && stoneSet !== undefined ? true : false;

  const myTurn = gameStarted && player === game.currentPlayer;
  console.log("🚀 ~ player", player);
  console.log("🚀 ~ gameStarted", gameStarted);
  console.log("🚀 ~ myTurn", myTurn);
  console.log("🚀 ~ winner", game.winner);
  console.log("🚀 ~ game", game);

  const updateGame = async (data: {
    title?: string;
    description?: string;
    iconSet?: number;
    expertMode?: boolean;
  }) => {
    const { title, description, iconSet, expertMode } = data;
    let update: {
      title?: string;
      description?: string;
      iconSet?: number;
      expertMode?: boolean;
    } = {};
    if (title) {
      update.title = title!;
    }
    if (description) {
      update.description = description!;
    }
    if (iconSet) {
      update.iconSet = iconSet!;
    }
    if (expertMode) {
      update.expertMode = expertMode!;
    }
    if (update !== {}) {
      await updateGameInDB(game!.id, update, setGame);
    }
  };

  const gameInfoHeaderProps = {
    game: game,
    gameStarted: gameStarted,
    myTurn: myTurn,
    player: player,
    stone: stone,
    series: series,
    updateGame: updateGame,
  };

  if (gameStarted && !player) {
    return (
      <>
        <GameInfoHeaderControl {...gameInfoHeaderProps} />
        <ChoosePlayerControl game={game} selectPlayer={selectPlayer} />
      </>
    );
  }

  if (!allNamespacesLoaded) return <></>;

  return (
    // <Paper
    //   elevation={0}
    //   sx={{ m: 2, p: 2, pb: 4, backgroundColor: "whitesmoke" }}
    // >
    <Box
      width="100%"
      display={"flex"}
      // alignItems="center"
      // alignItems="stretch"
      flexDirection={"column"}
      // bgcolor="yellow"
      pb={1}
      mb={1}
    >
      {!gameStarted && !game.winner && player && (
        <>
          <GameInfoHeaderControl {...gameInfoHeaderProps} />
          <ShareGameLinkControl game={game} />
        </>
      )}

      {!player && !game.winner && (
        <>
          <GameInfoHeaderControl editMode={true} {...gameInfoHeaderProps} />
          <ChoosePlayerControl game={game!} selectPlayer={selectPlayer} />
        </>
      )}
      {gameStarted && (
        <>
          <Box
            m={0}
            pb={1}
            // bgcolor="red"
            width="100%"
            display={"flex"}
            alignItems="center"
            flexDirection={"column"}
            // pt={1}
          >
            <GameInfoHeaderControl {...gameInfoHeaderProps} />

            {!game.winner && (
              <>
                <CurrentStoneInfoControl stone={stone} game={game} />
                <GameStatusInfoControl
                  myTurn={myTurn}
                  stone={stone}
                  player={player}
                  game={game}
                />
              </>
            )}
            {game.winner && (
              <Box
                display={"flex"}
                flexDirection="column"
                alignContent={"center"}
              >
                <Typography variant={"h4"} textAlign="center">
                  {t("gameControl:playerXHasWon", { winner: game.winner })}
                </Typography>
                <CreateRematchButton game={game} />
              </Box>
            )}

            <GameBoardControl
              game={game}
              setGame={setGame}
              myTurn={myTurn}
              placeStone={placeStone}
              winRow={winRow}
            />
          </Box>

          {!game.winner && (
            <SelectStoneDialog
              // selectedValue={stone}
              // open={open}
              open={!myTurn && !stone}
              onClose={handleClose}
              game={game}
              stones={stoneSet!}
            />
          )}
          {!game.winner && (
            <RemainingStonesControl game={game} stones={stoneSet!} />
          )}
        </>
      )}

      {/* {gameStarted && !game.winner && (
        <SelectStoneDialog
          // selectedValue={stone}
          // open={open}
          open={!myTurn && !stone}
          onClose={handleClose}
          game={game}
          stones={stoneSet!}
        />
      )} */}
      {/* </Paper> */}
    </Box>
  );
}

const CreateRematchButton = (props: {
  game: Game;
  iconOnly?: boolean;
  buttonTitle?: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { game, iconOnly, buttonTitle } = props;
  return (
    <>
      {!iconOnly && !game.rematchId && (
        <Button onClick={() => createRematch(game!, navigate)}>
          {buttonTitle || t("gameControl:playAgainButton")}
        </Button>
      )}
      {!iconOnly && game.rematchId && (
        <Button href={`/game/${game.rematchId}`}>
          {buttonTitle || t("gameControl:gotoRematchButton")}
        </Button>
      )}
    </>
  );
};
