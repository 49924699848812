import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
  PaperProps,
  SpeedDial,
  SpeedDialAction,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Stack from "@mui/material/Stack";
// Icons for SpeedDial Actions
import {
  BorderAll as BorderIcon,
  Palette as ColorIcon,
  Category as ShapeIcon,
  FormatSize as SizeIcon,
} from "@mui/icons-material";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useRef, useState } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { StoneControl } from "../controls/StoneControl";
import { Game, StoneType } from "../models";
import { getGameConfig } from "../utils/gameConfig";
import { GameBoardControl } from "./Game/GameBoardControl";

export interface SelectStoneDialogProps {
  open: boolean;
  // selectedValue?: Stone;
  onClose: (value: StoneType) => void;
  stones: StoneType[];
  game: Game;
}

export function PaperComponent(props: PaperProps) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("md"));

  let sx = {};
  if (xs) {
    sx = {
      minHeight: xs ? "100vh !important" : "100%",
      minWidth: xs ? "100vw !important" : "100%",
      maxHeight: xs ? "100vh !important" : "100%",
      maxWidth: xs ? "100vw !important" : "100%",
      margin: xs ? 0 : "",
    };
  }

  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} sx={{ m: "0 !important", p: 0, ...sx }} />
    </Draggable>
  );
}

const StoneSelectList = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
    justifyContent: "center",
    display: "flex",
  },
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(4, 1fr)",

    display: "grid",
    justifyContent: "space-around",
  },
  // gap: theme.spacing(2),

  // minWidth: "300px",
  // minHeight: "300px",
  // width: "100%",
  // height: "100%",
  // gridTemplateColumns: "repeat(4, "25%")",

  padding: theme.spacing(1),

  flexWrap: "wrap",

  alignContent: "space-around",
  // overflow: "auto",
}));

interface StoneFilter {
  color: 0 | 1 | null;
  size: 0 | 1 | null;
  variant: 0 | 1 | null;
  shape: 0 | 1 | null;
}

export function SelectStoneDialog(props: SelectStoneDialogProps) {
  const { onClose, open, stones, game } = props;
  const { t } = useTranslation();
  const { SHOW_CONFIRM_BUTTONS } = getGameConfig();

  const [filteredStones, setFilteredStones] = useState<StoneType[]>([]);

  const [showConfirm, setShowConfirm] = useState<number>(-1);
  const [toggleBoard, setToggleBoard] = useState<boolean>(false);

  const remainingStones = getRemainingStones(game, stones);

  const handleFiltered = React.useCallback(
    (filters: StoneFilter) => {
      const filteredStones = remainingStones.filter((stone) => {
        return (
          (filters.color === null || stone.color === filters.color) &&
          (filters.size === null || stone.size === filters.size) &&
          (filters.variant === null || stone.variant === filters.variant) &&
          (filters.shape === null || stone.shape === filters.shape)
        );
      });
      setFilteredStones(filteredStones);
    },
    [remainingStones]
  );

  // Filter the stones based on active filters

  const confirmStoneSelection = (stone: StoneType) => {
    onClose(stone);
  };

  return (
    <Dialog
      onClose={() => onClose}
      open={open}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          minHeight: "650px",
          maxHeight: "650px",
        },
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }}>
        <Typography variant="body1">
          {t("gameControl:chooseStoneTitle")}
        </Typography>
        <Typography variant="caption">
          {t("gameControl:chooseNextStoneForPlayerX", {
            player: game.currentPlayer === 1 ? game.player1 : game.player2,
          })}
        </Typography>
      </DialogTitle>

      {/* Toggle the board */}
      <Box mb={2} display="flex" justifyContent="center">
        <Button variant="outlined" onClick={() => setToggleBoard(!toggleBoard)}>
          {!toggleBoard && (
            <Typography>{t("gameControl:showBoardButton")}</Typography>
          )}
          {toggleBoard && (
            <Typography>{t("gameControl:chooseStoneButton")}</Typography>
          )}
        </Button>
      </Box>

      <FilterStones
        handleFiltered={handleFiltered}
        stones={stones}
        game={game}
      />
      {toggleBoard && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <GameBoardControl
            sx={{
              // mt: 0,
              // m: 4,
              height: "350px",
              width: "350px",
              minHeight: "350px !important",
              minWidth: "350px !important",
            }}
            game={game}
          />
        </Box>
      )}
      {!toggleBoard && (
        <>
          {/* Stone Selection List */}
          <Box display="flex" flexWrap="wrap" justifyContent="center" gap={2}>
            {filteredStones.map((stone, index) => (
              <React.Fragment key={`stone-${index}`}>
                {showConfirm !== index && (
                  <IconButton
                    key={`stoneIcon-${index}`}
                    sx={{
                      ml: 1,
                      "&.MuiButtonBase-root:hover": {
                        bgcolor: "whitesmoke",
                      },
                    }}
                    onClick={() => {
                      if (SHOW_CONFIRM_BUTTONS) {
                        setShowConfirm(index);
                      } else {
                        confirmStoneSelection(stone);
                      }
                    }}
                  >
                    <Avatar
                      sx={{
                        fontWeight: "inherit",
                        height: "75px",
                        width: "75px",
                        bgcolor: "white",
                        color: "white",
                      }}
                    >
                      <StoneControl stone={stone} iconSet={game?.iconSet} />
                    </Avatar>
                  </IconButton>
                )}
                {showConfirm === index && (
                  <IconButton sx={{ ml: 1 }}>
                    <CheckIcon onClick={() => confirmStoneSelection(stone)} />
                    <CancelIcon onClick={() => setShowConfirm(-1)} />
                  </IconButton>
                )}
              </React.Fragment>
            ))}
          </Box>
        </>
      )}
    </Dialog>
  );
}

function FilterStones({
  stones,
  handleFiltered,
  game,
  direction,
}: {
  stones: StoneType[];
  handleFiltered: (filters: StoneFilter) => void;
  game: Game;
  direction?: "up" | "down";
}) {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<StoneFilter>({
    color: null,
    size: null,
    variant: null,
    shape: null,
  });

  React.useEffect(() => {
    handleFiltered(filters);
  }, [filters]);

  // Handle filter toggle with null for deselect
  const handleFilterToggle = (key: keyof typeof filters, value: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: prevFilters[key] === value ? null : value, // Toggle selection with null for reset
    }));
  };

  // Handle chip removal
  const removeFilter = (key: keyof typeof filters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: null,
    }));
  };
  return (
    <Box width={"100%"}>
      <FilterSpeedDial
        direction={direction}
        filters={filters}
        handleFilterToggle={handleFilterToggle}
        stones={stones}
        game={game}
      />
      {/* Display Active Filters as Chips */}
      {filters && (
        <Box
          display="flex"
          flexWrap={"wrap"}
          justifyContent="center"
          gap={1}
          maxWidth={"100%"}
        >
          {Object.entries(filters).map(([key, value]) => {
            if (value !== null) {
              return (
                <Chip
                  size="small"
                  key={key}
                  label={`${t(`StoneSelectToolTips.${key}Label`)}: ${t(
                    `StoneSelectToolTips.${key}Label` + value
                  )}`}
                  onDelete={() => removeFilter(key as keyof typeof filters)}
                />
              );
            }
            return null;
          })}
        </Box>
      )}
    </Box>
  );
}

function FilterSpeedDial({
  filters,
  handleFilterToggle,
  stones,
  game,
  direction: position = "down",
}: {
  filters: {
    color: 0 | 1 | null;
    size: 0 | 1 | null;
    variant: 0 | 1 | null;
    shape: 0 | 1 | null;
  };
  handleFilterToggle: (key: keyof typeof filters, value: any) => void;
  stones: StoneType[];
  game: Game;
  direction?: "up" | "down";
}) {
  const { t } = useTranslation();

  const [openStates, setOpenStates] = useState<{ [key: string]: boolean }>({});
  const closeTimeoutsRef = useRef<{ [key: string]: NodeJS.Timeout | null }>({});

  const handleMouseEnter = (filterKey: string) => {
    if (closeTimeoutsRef.current[filterKey]) {
      clearTimeout(closeTimeoutsRef.current[filterKey]!);
      closeTimeoutsRef.current[filterKey] = null;
    }
    setOpenStates({ [filterKey]: true });
  };

  const handleMouseLeave = (filterKey: string) => {
    closeTimeoutsRef.current[filterKey] = setTimeout(() => {
      setOpenStates((prev) => ({ ...prev, [filterKey]: false }));
      closeTimeoutsRef.current[filterKey] = null;
    }, 500);
  };

  type FilterOption = {
    label: string;
    icon: React.ReactNode;
    key: keyof typeof filters;
    overrides?: Partial<Record<keyof typeof filters, 0 | 1>>;
    placement?:
      | "top"
      | "right"
      | "bottom"
      | "left"
      | "bottom-end"
      | "bottom-start"
      | "left-end"
      | "left-start"
      | "right-end"
      | "right-start"
      | "top-end"
      | "top-start";
  };

  const filterOptions: FilterOption[] = [
    {
      label: "colorLabel",
      icon: <ColorIcon />,
      key: "color",
    },
    {
      label: "sizeLabel",
      icon: <SizeIcon />,
      key: "size",
    },
    {
      label: "variantLabel",
      icon: <ShapeIcon />,
      key: "variant",
      placement: "left",
    },
    {
      label: "shapeLabel",
      icon: <BorderIcon />,
      key: "shape",
      placement: "left",
    },
  ];

  return (
    <Box width={"100%"} display={"flex"} justifyContent={"center"}>
      <Box
        display="flex"
        position="relative"
        maxWidth={"400px"}
        height={"40px"}
        justifyContent="space-around"
        mb={2}
      >
        {filterOptions.map((filter) => (
          <SpeedDial
            key={filter.label}
            ariaLabel={`Filter ${t(`StoneSelectToolTips.${filter.label}`)}`}
            icon={filter.icon}
            direction={position}
            FabProps={{
              sx: {
                bgcolor: "white",
                color: "black",
              },
              color: filters[filter.key] !== null ? "primary" : "default",
              size: "small",
            }}
            open={openStates[filter.key] || false}
            onOpen={() => {}}
            onClose={() => {}}
            onMouseEnter={() => handleMouseEnter(filter.key)}
            onMouseLeave={() => handleMouseLeave(filter.key)}
          >
            {[0, 1].map((value) => {
              const isSelected = filters[filter.key] === value;
              const defaultStone = {
                color: 0,
                size: 0,
                variant: 1,
                shape: 1,
              };

              const stoneCriteria = {
                ...defaultStone,
                [filter.key]: value,
              };

              const foundStone = stones.find((s) =>
                Object.entries(stoneCriteria).every(
                  ([key, val]) => s[key as keyof StoneType] === val
                )
              );

              const stone = foundStone || defaultStone;

              return (
                <SpeedDialAction
                  sx={{
                    margin: 2,
                  }}
                  key={`${filter.key}-${value}`}
                  tooltipOpen
                  tooltipPlacement={filter.placement || "right"}
                  icon={isSelected ? <CancelIcon /> : <CheckIcon />}
                  tooltipTitle={
                    <Stack
                      sx={{
                        transition: "background-color 0.3s, cursor 0.3s",
                        "&:hover": {
                          // backgroundColor: "lightgray",
                          cursor: "pointer",
                        },
                      }}
                      direction="column"
                      alignItems="center"
                      width={100}
                    >
                      <Typography fontWeight={"bold"} variant="caption">
                        {t(`StoneSelectToolTips.${filter.key}Label`)}
                      </Typography>
                      <Stack gap={1} direction="row" alignItems="center">
                        <Typography variant="caption">
                          {t(`StoneSelectToolTips.${filter.key}Label${value}`)}
                        </Typography>
                        <Avatar sx={{ bgcolor: "white" }}>
                          <StoneControl stone={stone} iconSet={game?.iconSet} />
                        </Avatar>
                      </Stack>
                    </Stack>
                  }
                  onClick={() => handleFilterToggle(filter.key, value)}
                />
              );
            })}
          </SpeedDial>
        ))}
      </Box>
    </Box>
  );
}

function getRemainingStones(game: Game, stones: StoneType[]) {
  const placedStones = game.board
    ?.filter((field) => {
      return field?.stone;
    })
    .map((f) => f?.stone?.key);

  const remainingStones = stones
    .filter((s) => {
      // console.log("filter remaining stones (stone): ", s);
      // console.log("filter remaining stones (board): ", game.board);
      return (
        !placedStones ||
        placedStones.length === 0 ||
        placedStones.indexOf(s.key) === -1
      );
    })
    .map((s) => {
      return {
        name: s.name,
        key: s.key,
        color: s.color,
        size: s.size,
        shape: s.shape,
        variant: s.variant,
        img: s.img,
      };
    });
  // console.log("🚀 ~ remainingStones", remainingStones);
  // console.log("🚀 ~ remainingStones winner?", game.winner);
  return remainingStones;
}

// function RemainingStonesControl(t, game: Game, remainingStones: { name: string | null | undefined; key: number | null | undefined; color: number | null | undefined; size: number | null | undefined; shape: number | null | undefined; variant: number | null | undefined; img: string | null | undefined; }[], theme) {
export function RemainingStonesControl(props: {
  game: Game;
  stones: StoneType[];
}) {
  const { t } = useTranslation();
  const { game, stones } = props;
  const [filteredStones, setFilteredStones] = useState<StoneType[]>([]);

  const remainingStones = getRemainingStones(game, stones);
  const handleFiltered = React.useCallback(
    (filters: StoneFilter) => {
      const filteredStones = remainingStones.filter((stone) => {
        return (
          (filters.color === null || stone.color === filters.color) &&
          (filters.size === null || stone.size === filters.size) &&
          (filters.variant === null || stone.variant === filters.variant) &&
          (filters.shape === null || stone.shape === filters.shape)
        );
      });
      setFilteredStones(filteredStones);
    },
    [remainingStones]
  );
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        backgroundColor: "white",
        border: "1px solid lightgray",
        // mx: 1,
        mt: 3,
        py: 2,
      }}
      display={"flex"}
      alignItems="center"
      flexDirection={"column"}
    >
      <Typography variant="overline">
        {t("gameControl:remainingStonesTitle", {
          count: game?.currentStone
            ? remainingStones.length - 1
            : remainingStones.length,
        })}
        :
      </Typography>
      <FilterStones
        handleFiltered={handleFiltered}
        stones={stones}
        game={game}
        direction={"up"}
      />
      <Box maxWidth={"100%"} width={"780px"}>
        <Box
          maxWidth={"600"}
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          justifyContent={"center"}
        >
          {/* {remainingStones.map((stone, index) => { */}
          {filteredStones.map((stone, index) => {
            if (game?.currentStone) {
              if (stone.key === game?.currentStone.key) {
                return <></>;
              }
            }

            return (
              <Avatar
                key={`remainingStone-${index}`}
                sx={{
                  margin: theme.spacing(1),
                  fontWeight: "inherit",
                  height: "75px",
                  width: "75px",
                  bgcolor: "white",
                  color: "white",
                }}
              >
                <StoneControl stone={stone} iconSet={game?.iconSet} />
              </Avatar>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
